const Data8 = [

    {
        carImage : 'images/01.png'
    },
    {
        carImage : 'images/02.png'
    },
    {
        carImage : 'images/03.png'
    },
    {
        carImage : 'images/04.png'
    },
    {
        carImage : 'images/05.png'
    },
    {
        carImage : 'images/06.png'
    },
    {
        carImage : 'images/07.png'
    },
    {
        carImage : 'images/08.png'
    },
]

export default Data8;
