import React from 'react';
import Demo2 from '../Demo2/Demo2';
import Demo5 from '../Demo5/Demo5';
import Demo9 from '../Demo9/Demo9';
import Menubar from '../Menubar/Menubar';


const Services = () => {
  return (
      <>
            <Menubar/> 
            <Demo2/>
            <Demo5/>
            <Demo9/>             
            
      </>
  )
};


export default Services;