import React from 'react';
import './Demo6.css'
import car from '../Demo6/car6.jpeg'

const Demo6 = () => {
  return (
      <>
          <section className='demo6'>
                <div className='demo-container6'>
                        <div className='demo-image6' data-aos="zoom-in">
                            <img src={car} alt="" />
                        </div>
                        <div className='demo-text6' data-aos="flip-left">
                        <div className='demo-imageText6'>
                        <div className='imageHeading6'>
                            {/* <img src={text} alt="" /> */}
                            <h1>One of the Best</h1>
                            </div>
                        </div>                            
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Expedita beatae, maxime debitis eaque dolorem adipisci, iure aliquam incidunt suscipit nisi nostrum quas accusamus at natus iste voluptates sint nemo alias optio dolor, sunt sit ab velit esse? Nostrum similique maiores aut molestias sint sed illum tempora!</p>
                            <button className = 'btn-demo6'><a href="">Join Now</a></button>
                            </div>
                            
                        </div>
                
          </section>
      </>
  )
};

export default Demo6;
