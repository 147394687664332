import React from 'react';
import Contact from '../Contact/Contact';
import Demo6 from '../Demo6/Demo6';
import Demo8 from '../Demo8/Demo8';
import Demo9 from '../Demo9/Demo9';
import Menubar from '../Menubar/Menubar';



const Contact_ = () => {
  return (
      <>
            <Menubar/> 
            <Demo6/>
            <Contact/>
            <Demo9/>             
            
      </>
  )
};


export default Contact_;