import React from 'react'
import '../Contact/Contact.css'
const Contact = () => {
  return (
    <>
        <section className='contact'>
        <div className='contact-heading'>
            <h1>Contact Us</h1>
        </div>
            <div className='contact-container'>
                <div className='contact-image' data-aos="zoom-in">
                    <img src="/images/contact.png" alt="" />
                </div>
                <div className='contact-text' data-aos="flip-left">
                    <input type="text" name="" id="" placeholder='Enter your Name' />
                    <input type="Email" name="" id="" placeholder='Enter your Email' />
                    <textarea name="" id="" cols="30" rows="10" placeholder='Enter your Message'></textarea>
                    <button className='contact-btn'>Submit</button>
                </div>
            </div>
        </section>
    </>
  )
}

export default Contact